import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const FiscalizationApiItem = ({ articleUrl, image, title, description }) => (
  <Link
    to={`/${articleUrl}`}
    key={title}
    className="flex flex-col rounded-lg shadow-lg overflow-hidden"
  >
    <div className="flex-shrink-0">
      <GatsbyImage
        image={useImageForData(image)}
        imgClassName="h-auto max-h-72 w-full object-cover"
        className="h-56"
        alt={title}
      />
    </div>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <div className="block mt-2">
          <p className="text-xl font-semibold text-gray-900">{title}</p>
          <p className="mt-3 text-base text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  </Link>
);

export default FiscalizationApiItem;
