import * as React from "react";
import PageTitle from "../components/elements/PageTitle";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import FeaturesSection from "../components/sections/FeaturesSection";
import FeaturesItem from "../components/elements/FeaturesItem";
import BidFeaturesSection from "../components/sections/BidFeaturesSection";
import BidFeaturesItem from "../components/elements/BidFeaturesItem";
import Title from "../components/elements/Title";
import FiscalizationApiSection from "../components/sections/FiscalizationApiSection";
import FiscalizationApiItem from "../components/elements/FiscalizationApiItem";
import { graphql } from 'gatsby'

const FiscalizationApiPage = ({ data }) => {
  const fiscalizationApiItems = data?.allMdx?.nodes?.filter(node => node.slug.startsWith('fiscalization-api/'))
  return (
    <div className="bg-white">
      <PageTitle
        pageTitle={"Fiscalization Api"}
        description={`Unlock the Potential of Your POS Software with CCU-Service Integration.`}
      />
      <Header />
      <Title title="Fiscalization Api" />
      <BidFeaturesSection>
        <BidFeaturesItem
          title="Elevate Your POS Software Today"
          description="Automation can revolutionize POS operations, offering efficiency, accuracy, and scalability. By leveraging this similar automation tools, businesses can optimize their retail operations, delight customers, and drive sustainable growth in an increasingly competitive landscape."
          btnText="Contact us"
          btnPath="/contacts"
          imgPath="pos_pos.jpg"
        />

        <FeaturesSection title="FISCALIZATION API" description="">
          <FeaturesItem title="End-to-End Automation" description="API automates the entire sales process, from setup and product management to transaction handling and reporting" />
          <FeaturesItem title="Customization" description="Customization of various parameters such as product details, tax rates, and payment methods to suit specific business requirements" />
          <FeaturesItem title="Integration" description="Integration with external systems and APIs (via HTTP requests) to perform tasks such as user creation, product setup, and transaction processing" />
          <FeaturesItem title="Scalability" description="API can handle a growing volume of transactions and product offerings without significant modifications" />
          <FeaturesItem title="Security" description="API  deals with sensitive data such as user credentials and financial information, ensuring proper security measures, such as encryption and access controls" />
          <FeaturesItem title="Logging and Monitoring" description="Implementing logging mechanisms to track script execution and monitoring tools to detect anomalies or failures can enhance operational visibility and troubleshooting." />
          <FeaturesItem title="UI Integration" description="powerful for backend automation, integrating a user-friendly interface for POS operations could improve usability, especially in retail environments" />
          <FeaturesItem title="Error Recovery" description="Enhancing error recovery mechanisms to handle exceptional scenarios gracefully, such as network failures or API errors" />
        </FeaturesSection>

      </BidFeaturesSection>
      {fiscalizationApiItems?.length > 0 && <FiscalizationApiSection title={"API DOCUMENTATION"} >
        {
          fiscalizationApiItems?.map(node => (
            <FiscalizationApiItem
              key={node.slug}
              articleUrl={node.slug}
              image={node.frontmatter.image}
              title={node.frontmatter.title}
              description={node.frontmatter.description}
            />
          ))
        }
      </FiscalizationApiSection>}
      <Footer />
    </div>
  )
}

export const query = graphql`
  query {
    allMdx {
      nodes {
        slug
        frontmatter {
          image
          title
          description
        }
      }
    }
  }
`

export default FiscalizationApiPage;
