import * as React from "react";

const FiscalizationApiSection = ({ title, children }) => (
  <section className="bg-white">
    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 text-center">
      {title}
    </h2>
    <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
      <div className="space-y-12">
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {children}
        </div>
      </div>
    </div>
  </section>
);

export default FiscalizationApiSection;
