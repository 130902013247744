import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { InboxIcon } from "@heroicons/react/outline";
import { useImageForData } from "../../hooks/useAllImages";

const BidFeaturesItem = ({ title, description, btnText, btnPath, imgPath }) => (
  <div className="relative">
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-amber-500">
              <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              {title}
            </h2>
            <p className="mt-4 text-lg text-gray-500">{description}</p>
            <div className="mt-6">
              <Link
                to={btnPath}
                className="inline-flex bg-amber-500 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-amber-300 hover:to-indigo-700"
              >
                {btnText}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="my-auto mt-12 sm:mt-16 mx-auto text-center px-4">
        <GatsbyImage
          image={useImageForData(imgPath)}
          className="rounded-xl"
          imgClassName="rounded-xl"
          alt={title}
        />
      </div>
    </div>
  </div>
);

export default BidFeaturesItem;
