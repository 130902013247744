import React from "react";

const BidFeaturesSection = ({ children }) => (
  <div className="relative overflow-hidden pt-24">
    <div
      aria-hidden="true"
      className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100 grid gap-24"
    />
    {children}
  </div>
);

export default BidFeaturesSection;
