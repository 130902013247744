import React from "react";

const FeaturesSection = ({ title, description, children }) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900">{title}</h2>
        <p className="mt-4 text-lg text-gray-500">{description}</p>
      </div>
      <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
        {children}
      </dl>
    </div>
  </div>
);

export default FeaturesSection;
